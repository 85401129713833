@import "./custom/custom.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

$fontFamily: "Poppins";

body {
  margin: 0;
  font-family: $fontFamily, sans-serif !important;
}

.bg-light-info {
  background-color: $light-info;
}
.bg-light-success {
  background-color: $light-success;
}
.bg-light-warning {
  background-color: $light-warning;
}
.bg-light-danger {
  background-color: $light-danger;
}

.bg-light-blue1 {
  background-color: $light-blue1;
}
.bg-light-blue2 {
  background-color: $light-blue2;
}

.bg-light-white {
  background-color: $light-white;
}

.bg-light-pink {
  background-color: $light-pink;
}

.bg-light-red {
  background: $light-danger;
}
.text-red {
  color: $status-danger;
}
.text-success {
  color: $status-success;
}
.text-info {
  color: $status-info;
}
.text-warning {
  color: $status-warning;
}

.text-grey {
  color: $grey !important;
}
.bg-grey {
  background: $grey !important;
}

.bg_image {
  background-color: #ffffff15;

  background-image: url("../assets/bg_image.png"),
    linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 36, 134, 1) 30%,
      rgba(0, 38, 143, 1) 49%,
      rgba(0, 37, 137, 1) 70%,
      rgba(0, 0, 0, 1) 100%
    );
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: overlay;
}

.imgLogo {
  width: 192px;
  @media (min-width: 1200px) {
    width: 9.5vm;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.active-border {
  border: 1px solid $primary;
}

.marginOpen {
  margin-left: 240px;
}
.marginClose {
  margin-left: 70px;
}

.email-container {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
}



.otp-box {
  width: 56px;
  height: 56px;
  border: 2px solid $text-underline;
  &:focus-visible {
    outline-color: $primary !important;
  }
  @media (min-width: 1320px) {
    width: 60px;
    height: 60px;
  }
}

.dropBox {
  border: dashed 2px gray;
  display: inline-block;
  border-radius: 5px;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #f8f8f8;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #e1e1e1;
  border-radius: 16px;
}

/* Define a class to hide the scrollbar */
.hide-scrollbar {
  overflow: hidden;
}

/* For Webkit browsers (Chrome, Safari, etc.) */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.hide-scrollbar {
  scrollbar-width: none;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-webkit-reveal {
  display: none;
}

.profile-picture {
  border: 2px solid #dde1ff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.profile-picture-50 {
  border: 2px solid #dde1ff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.rotate-center-right {
  position: fixed;
  right: -45px;
  top: 50%;
  z-index: 1900;
  transform: rotate(270deg);
}



@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.animate-slide {
  animation: slideInFromLeft 0.5s ease-in-out forwards;
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    
  }
}

.animate-slide-out {
  animation: slideOutToLeft 0.5s ease-in-out forwards;
}



.content {
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
}

.open {
  width: 400px; /* Adjust width as needed */
}

.closed {
  width: 0;
}

.button {
  cursor: pointer;
  background-color: lightgray;
  padding: 10px;
}

// @ali-tas/htmldiff-js lib - styles
.diffmod {
  text-decoration: none;
}
.diffins {
  text-decoration: none;
}
.diffdel {
  text-decoration: none;
}
del {
  background-color: #FD8A8A !important;
}
ins {
  background-color: yellow !important;
}

// filename link
.custom-file-link {
  cursor: pointer;
  color: #000;

  &:hover {
    color: gray;
  }
}

// drop file
.drop-area {
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border: 2px dashed grey;
  border-radius: 5px;
  height: 60px;
  transition: transform 0.3s ease;

  &.drag-over {
    border: 2px dashed $primary;
    transform: scale(1.05);
  }
}
