$primary: #00268f;
$secondary: #d7b24d;

//extra
$light: #eff1f6;
$grey: #757682;

$light-blue1: #dde1ff;
$light-blue2: #ecf0f9;
$light-white: #f5f8ff;
$light-pink: #f8faff;

$light-brown: #9d5e00;
$light-peach: #fbd6bc;

$light-dark: #3a3a3d;
$text-underline: #e5e5ff;

$status-info: #0081c1;
$status-success: #45b26b;
$status-warning: #ff881a;
$status-danger: #ba1a1a;

$light-info: #b7dff2;
$light-success: #cffae8;
$light-warning: #fbd6bc;
$light-danger: #ffdad6;

$light-secondary: #fff8e3;
